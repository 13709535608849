import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { InstagramImageComponent } from './instagram.image.component';
import { ServicesModule } from '../services/services.module';
import { SocialContainerComponent } from './social.container.component';
import { ContactsComponent } from './contacts.component';
import { ProgressSpinnerComponent } from './progress.spinner.component';
import { ResultOverlayComponent } from './result.overlay.component';

/**
 * Components module.
 */
@NgModule({
    declarations: [
        ContactsComponent,
        InstagramImageComponent,
        ProgressSpinnerComponent,
        ResultOverlayComponent,
        SocialContainerComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatProgressSpinnerModule,
        ServicesModule,
    ],
    exports: [
        ContactsComponent,
        InstagramImageComponent,
        ProgressSpinnerComponent,
        ResultOverlayComponent,
        SocialContainerComponent,
    ]
})
export class ComponentsModule {

}
