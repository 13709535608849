import { Component } from '@angular/core';
import { InstagramService } from '../services/instagram.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  showContacts = false;

  constructor(instagramService: InstagramService) {
    instagramService.refreshToken();
  }
}
