import { Component, EventEmitter, Output, ViewChild, HostListener, HostBinding } from '@angular/core';
import {
    AnimationEvent,
    trigger,
    style,
    transition,
    animate,
    keyframes,
    group } from '@angular/animations';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { UserDetails } from 'src/utils/value.types';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.less'],
    animations: [
        trigger('open', [
            transition(':enter', [
                style({left: '50%', right: '50%'}),
                animate(600, style({left: 0, right: 0})),
            ]),
            transition(':leave', [
                style({left: 0, right: 0}),
                animate(400, style({left: '50%', right: '50%'}))
            ])
        ]),
        trigger('hideCloseIcon', [
            transition('false => true', [
                style({opacity: 1, transform: 'rotate(360deg)'}),
                group([
                    animate(300, style({transform: 'rotate(0deg)'})),
                    animate(700, style({opacity: 0}))])
            ]),
            transition('true => false', [
                style({opacity: 0, transform: 'rotate(0deg)'}),
                group([
                    animate(300, style({transform: 'rotate(360deg)'})),
                    animate(700, style({opacity: 1}))])
            ])
        ]),
        trigger('showUpload', [
            transition(':enter', [
                animate(600, keyframes([
                    style({'max-height': 0}),
                    style({'max-height': '300px'}),
                ]))
            ]),
            transition(':leave', [
                animate(600, keyframes([
                    style({'max-height': '300px'}),
                    style({'max-height': 0}),
                ]))
            ])
        ]),
    ]
})
export class ContactsComponent {
    private artFileNameValue: string;

    @HostBinding('@open')
    readonly userDetails: UserDetails = {sendArt: false};

    showCloseIcon = false;
    loading = false;
    hasError?: boolean;

    get artFileName(): string {
        return this.artFileNameValue;
    }

    @ViewChild('form')
    ngForm: NgForm;

    @Output()
    submitted = new EventEmitter<void>();

    constructor(private readonly apiService: ApiService) { }

    closeForm(): void {
        this.showCloseIcon = false;
        setTimeout(() => {
            this.submitted.emit();
        }, 300);
    }

    updateArtFile(artFile: File|undefined|null): void {
        if (artFile) {
            this.userDetails.artFile = artFile;
            this.artFileNameValue = artFile.name;
        }
    }

    sendMessage(event: Event, ngForm: NgForm): void {
        if (this.ngForm.invalid) {
            return;
        }
        //  event.preventDefault();

        this.loading = true;
        this.apiService.uploadMessage(this.userDetails).then(result => {
            console.log('Result: ', result);
            this.hasError = false;
        }).catch(error => {
            console.log(error);
            this.hasError = true;
        }).finally(() => {
            this.loading = false;
        });
    }

    resultCallback(): void {
        if (!this.hasError) {
            this.ngForm.resetForm();
            this.closeForm();
        } else {
            (this.ngForm as {submitted: boolean}).submitted = false;
        }
        this.hasError = undefined;
    }

    @HostListener('@open.done', ['$event'])
    private openAnimationState(event: AnimationEvent): void {
        if (event.fromState === 'void') {
            this.showCloseIcon = true;
        }
    }
}
