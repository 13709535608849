<form id="contacts-form" (submit)="sendMessage($event, form)" #form="ngForm">
    <button type="button" mat-icon-button id="close-contacts-form" [@hideCloseIcon]="!showCloseIcon"
            [style.opacity]="showCloseIcon ? 1 : 0" (click)="closeForm()">
        <mat-icon>close</mat-icon>
    </button>
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input type="text" id="full-name" matInput required name="name" [(ngModel)]="userDetails.name">
        <mat-error>Please enter your name</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input type="email" id="email" matInput required name="email" 
                [(ngModel)]="userDetails.email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
        <mat-error>Please enter valid email</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Message</mat-label>
        <textarea id="message" matInput required name="message" 
                    rows="4" cols="40" [(ngModel)]="userDetails.message"></textarea>
                    <mat-error>Please add some message</mat-error>
    </mat-form-field>
    <mat-checkbox id="trash-it-up-checkbox" name="trash-it-up" [(ngModel)]="userDetails.sendArt">
        Trash It Up!
    </mat-checkbox>
    <div id="contacts-form-upload-container" *ngIf="userDetails.sendArt" [@showUpload]>
        <p>Share your art on our Instagram</p>
        <mat-form-field>
            <mat-label>Art Title</mat-label>
            <input type="text" id="art-name" matInput required
                    name="artName" [(ngModel)]="userDetails.artName">
            <mat-error>Please name your art</mat-error>
        </mat-form-field>
        <div id="file-upload-container">
            <mat-form-field>
                <mat-label>Art File</mat-label>
                <input type="text" id="upload-file" matInput readonly disabled 
                    [ngModel]="artFileName" name="artFile">
            </mat-form-field>
            <button mat-icon-button type="button" id="attach-file">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" id="art-file" (change)="updateArtFile($event.target?.files[0])">
        </div>
    </div>
    <button type="submit" mat-stroked-button [disabled]="form.invalid">
        Submit
    </button>
</form>
<app-progress-spinner *ngIf=loading></app-progress-spinner>
<app-result-overlay [isError]="hasError" *ngIf="hasError !== undefined" (ok)="resultCallback()">
</app-result-overlay>