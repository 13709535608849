import { NgModule } from '@angular/core';
import { InstagramService } from './instagram.service';
import { ApiService } from './api.service';

/**
 * Services module.
 */
@NgModule({
    providers: [ApiService, InstagramService]
})
export class ServicesModule {}
