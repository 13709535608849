import { Component, EventEmitter, Input, Output, HostBinding } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-result-overlay',
    template: `<div id="submition-result">
                    <span *ngIf="!isError" id="success-submission">Message succesfully sent</span>
                    <span *ngIf="isError" id="failure-submission">Please try again later</span>
                    <button (click)="ok.emit()" class="link">OK</button>
                </div>`,
    styleUrls: ['./result.overlay.component.less'],
    animations: [
        trigger('showHide', [
            transition(':leave', [
                style({opacity: 1}),
                animate(500, style({opacity: 0}))
            ])
        ])
    ],
})
export class ResultOverlayComponent {
    @HostBinding('@showHide')
    private readonly hostBinding = true;

    @Input()
    isError?: boolean;

    @Output()
    ok = new EventEmitter<void>();
}
