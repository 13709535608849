import { Component, Input } from '@angular/core';

/**
 * Component renders social networks icons in two modes.
 */
@Component({
    selector: 'app-social-container',
    templateUrl: 'social.container.component.html',
    styleUrls: ['social.container.component.less']
})
export class SocialContainerComponent {
    @Input()
    isFollowMode = false;

    createImageTag(name: string): string {
        return `<img src="../assets/social_icons/${name}.svg" alt="${name}" />`;
    }
}
