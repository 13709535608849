import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { ConstantPool } from '@angular/compiler';

const INSTAGRAM_API_URL_BASE = 'https://api.instagram.com/v1/users/self/media/recent?access_token=';
const INSTAGRAM_API_TOKEN = '6290059248.1677ed0.0ecfc8e2223e49c8a7cd15f81bda8d8f';
const INSTAGRAM_API_URL = `${INSTAGRAM_API_URL_BASE}${INSTAGRAM_API_TOKEN}`;
const INSTAGRAM_API_URL_WITH_CALLBACK = `${INSTAGRAM_API_URL_BASE}${INSTAGRAM_API_TOKEN}&callback=parseResponse`;

const BASIC_DISPLAY_API_TOKEN = 'IGQVJWTjYzSUx4azBvQWFEVFJxQWhmV1pYM0g2OVJSakZAuZAzdPUXpYaWVPQ3pZAcDlmTU03VEl2cjlQM2hUaV9tLTlrZAlhCV1g3dnNjTk1WQVYtdklmaUcteUwtR0lZAWUJOa1F4d1VOb0t1TmJlWTdLaQZDZD';
const BASIC_DISPLAY_API_URL = `https://graph.instagram.com/me/media?fields=media_url,media_type&limit=300&access_token=${BASIC_DISPLAY_API_TOKEN}`;
const REFRESH_TOKEN_URL = `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${BASIC_DISPLAY_API_TOKEN}`;

// https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=IGQVJWTjYzSUx4azBvQWFEVFJxQWhmV1pYM0g2OVJSakZAuZAzdPUXpYaWVPQ3pZAcDlmTU03VEl2cjlQM2hUaV9tLTlrZAlhCV1g3dnNjTk1WQVYtdklmaUcteUwtR0lZAWUJOa1F4d1VOb0t1TmJlWTdLaQZDZD


interface InstagramResponse {
    error: {message: string};
    data: {media_type: 'VIDEO'|'IMAGE', media_url: string}[];
}

interface ExtendedWindow extends Window {
    parseResponse: (response: InstagramResponse) => void;
}

declare const window: ExtendedWindow;

@Injectable()
export class InstagramService {
    private isLoadedSubject = new Subject<string[]>();

    get isLoaded(): Observable<string[]> {
        return this.isLoadedSubject.asObservable();
    }

    refreshToken(): Promise<{}> {
        return fetch(REFRESH_TOKEN_URL, {method: 'GET'});
    }

    getImages(): Promise<string[]> {
        return fetch(BASIC_DISPLAY_API_URL, {method: 'GET'}).then(result => result.json()).then(
            (json: InstagramResponse) => {
                if (!json.error && json.data.length) {
                    return json.data.filter(d => d.media_type === 'IMAGE').map(d => d.media_url);
                } else {
                    throw new Error(json.error.message);
                }
            });
    }
}
