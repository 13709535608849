import { Component } from '@angular/core';

@Component({
    template: `<svg>
                    <circle cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10" />
                </svg>`,
    selector: 'app-progress-spinner',
    styleUrls: ['./progress.spinner.component.less']
})
export class ProgressSpinnerComponent {

}
