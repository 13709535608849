<div id="follow-container" *ngIf="isFollowMode">
    <span>Follow us</span>
    <a href="http://www.instagram.com/trash_fusion" target="_blank" [innerHtml]="createImageTag('Instagram')"></a>
    <a href="https://twitter.com/trash_fusion/" target="_blank" [innerHtml]="createImageTag('Twitter')"></a>
    <a href="https://www.pinterest.com/trashfusion/" target="_blank" [innerHtml]="createImageTag('Pinterest')"></a>
    <a href="https://www.facebook.com/trashfusionart/" target="_blank" [innerHtml]="createImageTag('Facebook')"></a>
</div>
<div id="social-container" *ngIf="!isFollowMode">
    <span>Share</span>
    <a href="http://www.facebook.com/sharer.php?u=https://trashfusion.org" target="_blank" [innerHtml]="createImageTag('Facebook')"></a>
    <a href="https://twitter.com/share?url=https://trashfusion.org&amp;text=Trash%20Fusion:%20The%20Art%20We%20Do&amp;hashtags=trashfusion"
       target="_blank" [innerHtml]="createImageTag('Twitter')">
    </a>
    <a href="javascript:void((function()%7Bvar%20e=document.createElement('script');e.setAttribute('type','text/javascript');e.setAttribute('charset','UTF-8');e.setAttribute('src','https://assets.pinterest.com/js/pinmarklet.js?r='+Math.random()*99999999);document.body.appendChild(e)%7D)());" 
       [innerHtml]="createImageTag('Pinterest')">
    </a>
    <a href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://trashfusion.org" target="_blank" [innerHtml]="createImageTag('LinkedIn')"></a>
</div>