import { Component, OnInit } from '@angular/core';
import { InstagramService } from '../services/instagram.service';

const IMAGE_SHOW_INTERVAL = 10000;

/**
 * Component renders Instagram image within given interval.
 */
@Component({
    selector: 'app-instagram-image',
    template: `<img id="instagram-image" class="full-frame" [src]="imgUrl" *ngIf="imgUrl">`,
    styleUrls: ['instagram.image.component.less']
})
export class InstagramImageComponent implements OnInit {
    imgUrl = '';

    constructor(private readonly instagramService: InstagramService) {
    }

    ngOnInit(): void {
        this.instagramService.getImages().then(this.loadImage.bind(this)).catch(error => {
            console.log(error);
        });
    }

    private loadImage(imgUrls: string[]): void {
        const index = Math.floor(Math.random() * imgUrls.length);

        this.imgUrl = '';
        setTimeout(() => {
            this.imgUrl = imgUrls[index];
        }, 0);

        setTimeout(() => this.loadImage(imgUrls), IMAGE_SHOW_INTERVAL);
    }
}
