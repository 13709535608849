<app-instagram-image></app-instagram-image>
<div id="mesh-overlay" class="full-frame"></div>
<app-social-container [isFollowMode]="true"></app-social-container>
<div id="logo-container">
  <div>
    <img src="../assets/images/logo_round.svg" />
    <button (click)="showContacts=true" id="contacts-link" class="link">Contacts</button>
  </div>
</div>
<app-contacts *ngIf="showContacts" (submitted)="showContacts=false"></app-contacts>
<app-social-container></app-social-container>