import { Injectable } from '@angular/core';
import { UserDetails } from 'src/utils/value.types';
import { getApiUrl } from 'src/utils/environments';

@Injectable()
export class ApiService {
    uploadMessage(userDetails: UserDetails): Promise<boolean> {
        userDetails = Object.assign({}, userDetails);
        return userDetails.sendArt && userDetails.artFile ?
            this.readFile(userDetails.artFile as File).then(fileResponse => {
                userDetails.artFile = fileResponse;
                return this.fetchRequest(userDetails);
            }) : this.fetchRequest(userDetails);
    }

    private readFile(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            try {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    resolve(reader.result as string);
                };
            } catch (error) {
                reject(error);
            }
        });
    }

    private async fetchRequest(userDetails: UserDetails): Promise<boolean> {
        const response = await fetch(`${getApiUrl()}/postMessage`, {
            method: 'POST',
            body: JSON.stringify(userDetails)
        });

        if (response.ok) {
            const jsonData = await response.json();
            if (!jsonData.result && jsonData.error) {
                throw new Error(jsonData.error);
            }
            return true;
        } else {
            throw new Error(`Response error: ${response.statusText}`);
        }
    }
}
